<template>
  <div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'

import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner, BBadge, BCardTitle, BCardText, BAvatar, BCardHeader,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BCardHeader,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,
    BBadge,
    BCardTitle,
    BCardText,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const form = reactive({
      libelle: '',
      description: '',
      prix: '',
      nombre: '',
    })
    /* eslint-disable global-require */
    onMounted(() => {

    })
    const formatter = value => value.toUpperCase()
    return {
      form,
      formatter,
    }
  },
  data() {
    return { datas: {} }
  },
  methods: {
    onDecode(result) { console.log(result) },
    onLoaded(result) { console.log(result) }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
